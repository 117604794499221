<template>
  <el-card class="box-card full-screen recharge-apply">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('orgName')">
          <el-select v-model="queryForm.orgCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' -- '+(t.orgInfoCode)" :value="t.orgInfoCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('businessName')">
          <el-select v-model="queryForm.businessCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,i) in businessList">
              <el-option-group v-if="item.parentId==0" :key="i" :label="$t(item.name)">
                <template v-for="(t,i) in businessList">
                  <el-option v-if="t.parentId==item.id" :key="i" :label="$t(t.name)" :value="t.businessCode"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('region')">
          <el-select v-model="queryForm.region" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <!-- 国 -->
            <template v-for="(item,i) in countryList">
              <el-option-group v-if="item.parentId==0" :key="i" :label="$t(item.name)">
                <!-- 省 -->
                <template v-for="(t,i) in countryList">
                  <el-option v-if="t.parentId==item.id" :key="i" :label="$t(t.name)" :value="t.id"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('urban')">
          <el-select v-model="queryForm.orgCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,i) in countryList">
              <template v-if="item.parentId==0">
                <template v-for="(it,i2) in countryList">
                  <el-option-group v-if="(!queryForm.province&&it.parentId==item.id)||(queryForm.province&&it.id==queryForm.province&&it.parentId==item.id)" :key="i.toString()+i2" :label="$t(item.name+'/'+it.name)">
                    <template v-for="(em,i3) in countryList">
                      <el-option v-if="em.parentId==it.id" :key="i.toString()+i2.toString()+i3" :label="$t(em.name)" :value="em.id"></el-option>
                    </template>
                  </el-option-group>
                </template>
              </template>
            </template>
          </el-select>
        </el-form-item> -->

        <el-form-item :label="$t('date')">
          <el-date-picker v-model="queryForm.date" type="daterange" range-separator="~" :start-placeholder="$t('startDate')" :end-placeholder="$t('endDate')" value-format="yyyy-MM-dd" size="small" unlink-panels :clearable="false" :picker-options="pickerOptions0" @change='changeDate'>
          </el-date-picker>
        </el-form-item>

        <el-form-item :label="$t('customerName')">
          <el-input v-model="queryForm.customerName" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Shop account')">
          <el-input v-model="queryForm.employeeId" size="small" :placeholder="$t('Shop account')"></el-input>
        </el-form-item>

        <el-form-item :label="$t('type')">
          <el-select v-model="queryForm.type" size="small">
            <el-option :label="$t('detail')" :value="1"></el-option>
            <el-option :label="$t('daysStatistical')" value="2"></el-option>
            <el-option :label="$t('monthlyStatistics')" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns">
        <a @click="downloadEXCEL()" type="text" size="mini"><i class="el-icon-download"></i> {{$t('downloadEXCEL')}}</a>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="index" align="center" fixed="left" min-width="50"></el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" min-width="160" align="center">
          <template slot-scope="scope"><span class="franc">₣</span>{{Number(scope.row.amount).formatMoney(0)}}</template>
        </el-table-column>
        <el-table-column v-if="isDetail" prop="businessName" :label="$t('businessName')" min-width="200">
          <template slot-scope="scope">{{$t(scope.row.businessName)}}</template>
        </el-table-column>
        <el-table-column prop="orgCode" :label="$t('orgCode')" min-width="160"></el-table-column>
        <el-table-column prop="orgName" :label="$t('orgName')" min-width="160"></el-table-column>
        <el-table-column prop="regionName" :label="$t('region')" min-width="160"></el-table-column>
        <el-table-column prop="platformRemunerationAmount" :label="$t('platformRemunerationAmount')" min-width="160" align="center">
          <template slot-scope="scope"><span class="franc">₣</span> {{scope.row.platformRemunerationAmount.formatMoney(0)}}</template>
        </el-table-column>
        <el-table-column v-if="isDetail" prop="remuneration" :label="$t('remunerationRules')" min-width="160"></el-table-column>
        <el-table-column prop="sourceRemunerationAmount" :label="$t('sourceRemunerationAmount')" min-width="160" align="center">
          <template slot-scope="scope"><span class="franc">₣</span>{{scope.row.sourceRemunerationAmount.formatMoney(0)}}</template>
        </el-table-column>
        <el-table-column v-if="isDetail" prop="customerName" :label="$t('customerName')" min-width="200"></el-table-column>
        <el-table-column prop="transactionCode" :label="$t('transactionCode')" min-width="200"></el-table-column>
        <el-table-column v-if="isDetail" prop="creatorName+creatorSurname(employeeId)" :label="$t('tradeAssistant')" min-width="200">
          <template slot-scope="scope">
            {{(scope.row.creatorName||'')+' '+(scope.row.creatorSurname||'')}}({{scope.row.employeeId||scope.row.createId}})
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" min-width="160">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format(queryForm.type==2||queryForm.type==3?'ymd':'ymdhms'   )}}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />
  </el-card>
</template>

<script>
import remunerationStatistics from '@/api/remunerationStatistics'
import business from '@/api/business'
import country from '@/api/country'
import org from '@/api/org'
import qs from 'qs'

export default {
  name: "remunerationStatements",
  data () {
    return {
      pickerOptions0: {
        onPick: ({ maxDate, minDate }) => {
          this.minDate = minDate;
          this.maxDate = maxDate;
        },
        disabledDate: (time) => {
          let today = 3 * 30 * 24 * 3600 * 1000;
          if (this.minDate) {
            return time.getTime() > Date.now() || time > new Date(this.minDate.getTime() + today) || time < new Date(this.minDate.getTime() - today)
          }
          return time.getTime() > Date.now();
        },
      },
      isLoading: false,
      //table高度，动态设置
      queryTableHeight: String,
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      /**查询条件  [new Date("2021-08-01"), new Date("2021-10-01")] */
      queryForm: { size: Number, current: Number, type: 1, date: [new Date(this.$manba().format('ym') + "-01"), new Date()] },
      businessList: Array,
      countryList: Array,
      /**是否是明细 */
      isDetail: true,

      orgList: [],
      rules: {
        date: [{ required: true, message: '', trigger: 'blur' },],
      }
    }
  },
  computed: {
    downloadUrl () {
      let _this = this;
      let param = JSON.parse(JSON.stringify(_this.queryForm));
      let params = {};
      for (let k in param) {
        if (k == "date" && param[k] && param[k].length == 2) {
          params.startTime = _this.$manba(param.date[0]).format('ymd');
          params.endTime = _this.$manba(param.date[1]).format('ymd');
        }
        else if (param[k]) {
          params[k] = param[k]
        }
      }

      delete param.date;
      delete param.size;
      delete param.current;

      return remunerationStatistics.remunerationDetailedExport + '?' + qs.stringify(param)
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  watch: {
    "queryForm.date" (newVal) {
      if (newVal && newVal.length > 0) {
        this.queryForm.startTime = this.queryForm.date[0];
        this.queryForm.endTime = this.queryForm.date[1];
      } else {
        this.queryForm.startTime = undefined;
        this.queryForm.endTime = undefined;
      }
    },
    // "queryForm.orgCode" (newVal) { }
  },
  methods: {
    downloadEXCEL () {
      if (this.$manba(this.queryForm.date[1]).distance(this.queryForm.date[0]) > 90) {
        this.$confirm(this.$('The end time cannot be later than 90 days after the start time')).then(() => { }).catch(() => { });
        return;
      }
      let download = document.createElement("a");
      download.href = this.downloadUrl;
      download.download = '';
      download.click();
    },

    changeDate (e) {
      if (e === null) {
        this.minDate = "";
        this.maxDate = "";
        this.pickerBeginDateBefore = {
          disabledDate: (time) => {
            let three = 3 * 30 * 24 * 3600 * 1000;
            if (this.minDate) {
              return time.getTime() > Date.now() || time > new Date(this.minDate.getTime() + three) || time < new Date(this.minDate.getTime() - three)
            }
            return time.getTime() > Date.now();
          },
        };
      }
    },
    getList () {
      let _this = this;
      _this.isLoading = true;
      let params = JSON.parse(JSON.stringify(_this.queryForm))
      if (params.date && params.date.length > 0) {
        params.startTime = _this.$manba(params.date[0]).format('ymd');
        params.endTime = _this.$manba(params.date[1]).format('ymd');
      }
      else {
        params.startTime = undefined;
        params.endTime = undefined;
      }
      delete params.date;

      remunerationStatistics.remunerationDetailed({
        param: {
          current: _this.currentPage,
          size: _this.pageSize,
          ...params,
        },
        success: res => {
          _this.isLoading = false;
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },

    getBusiness () {
      let _this = this;
      business.getList({
        success: res => {
          _this.$set(_this, "businessList", res.data.reverse())
          _this.getList();
        }
      })
    },
    getOrgList () {
      let _this = this;
      org.getOrgInfoPage({
        param: { current: 1, size: 10000, },
        success: res => {
          if (res.data)
            _this.orgList = res.data.records;
        }
      })
    },
    getCountry () {
      let _this = this;
      country.getList({
        success: res => {
          _this.$set(_this, "countryList", res.data);
        }
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getBusiness();
    this.getCountry()
    if (this.loginInfo.orgType == 1) {
      this.getOrgList();
    }
  },
}
</script>

<style>
</style>